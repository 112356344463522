import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { treinamentoStore, departamentoStore, userToolStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newTreinamento } from '@/interfaces/treinamento';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.ToolHeaders = [
            { text: 'Módulo', value: 'name' },
            { text: 'Submódulo', value: 'group' },
        ];
        this.toolSearch = '';
        this.id = null;
        this.entityObject = null;
        this.selectedItem = {};
        this.departamentoSelected = null;
    }
    get departamentoOptions() {
        return departamentoStore.departamentos;
    }
    get treinamentos() {
        return treinamentoStore.treinamentos;
    }
    get userToolsSchool() {
        return userToolStore.userToolsSchool;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            const result = await treinamentoStore.updateTreinamento(this.entityObject);
        }
        else {
            const result = await treinamentoStore.createTreinamento(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-treinamentos' });
    }
    async mounted() {
        await userToolStore.getToolsSchool();
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await treinamentoStore.getTreinamento(parseInt(this.$router.currentRoute.params.id, 0));
            this.departamentoSelected = this.entityObject.departamento_id;
        }
        else {
            this.entityObject = newTreinamento();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
        },
    })
], Create);
export default Create;
