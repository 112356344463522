var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Treinamento")])]),_c('v-card-subtitle',[(_vm.id)?_c('div',[_vm._v("Atualizar Registro")]):_c('div',[_vm._v("Criar Registro")])]),(_vm.entityObject)?_c('v-card-text',[[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Título","vid":"titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Título","error-messages":errors},model:{value:(_vm.entityObject.title),callback:function ($$v) {_vm.$set(_vm.entityObject, "title", $$v)},expression:"entityObject.title"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Duração","vid":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Duração em minutos","error-messages":errors,"type":"number"},model:{value:(_vm.entityObject.duration),callback:function ($$v) {_vm.$set(_vm.entityObject, "duration", $$v)},expression:"entityObject.duration"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Departamento","vid":"departamentoSelected","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.departamentoOptions,"item-text":"nome","item-value":"id","label":"Departamento","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.departamentoSelected),callback:function ($$v) {_vm.departamentoSelected=$$v},expression:"departamentoSelected"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Descrição","vid":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Descrição","error-messages":errors},model:{value:(_vm.entityObject.description),callback:function ($$v) {_vm.$set(_vm.entityObject, "description", $$v)},expression:"entityObject.description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Script","vid":"script","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Script","error-messages":errors},model:{value:(_vm.entityObject.script),callback:function ($$v) {_vm.$set(_vm.entityObject, "script", $$v)},expression:"entityObject.script"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.toolSearch),callback:function ($$v) {_vm.toolSearch=$$v},expression:"toolSearch"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.ToolHeaders,"items":_vm.userToolsSchool,"search":_vm.toolSearch,"dense":"","fixed-header":"","height":"50vh","hide-default-footer":"","loading-text":"Carregando...","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name.replace(/prospex/ig, _vm.getNomeProspex()).replace(/cda/ig, _vm.getNomeCDA()).replace(/reforços/ig, _vm.getNomeAulaExtraPlural()))+" ")]):_vm._e()]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(" "+_vm._s(item.group.replace(/prospex/ig, _vm.getNomeProspex()).replace(/cda/ig, _vm.getNomeCDA()).replace(/reforços/ig, _vm.getNomeAulaExtraPlural()))+" ")]):_vm._e()]}},{key:"item.allow_view",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{model:{value:(item.allow_view),callback:function ($$v) {_vm.$set(item, "allow_view", $$v)},expression:"item.allow_view"}})]}},{key:"item.allow_edit",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{model:{value:(item.allow_edit),callback:function ($$v) {_vm.$set(item, "allow_edit", $$v)},expression:"item.allow_edit"}})]}},{key:"item.allow_delete",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{model:{value:(item.allow_delete),callback:function ($$v) {_vm.$set(item, "allow_delete", $$v)},expression:"item.allow_delete"}})]}},{key:"item.allow_create",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{model:{value:(item.allow_create),callback:function ($$v) {_vm.$set(item, "allow_create", $$v)},expression:"item.allow_create"}})]}}],null,true)})],1)],1)]],2):_vm._e(),_c('v-card-actions',[_c('save-button',{ref:"saveButton",attrs:{"propVoltarRoute":"main-treinamentos"},on:{"submit":_vm.submit}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }