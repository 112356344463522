export function newTreinamento() {
    return {
        title: '',
        description: '',
        script: '',
        duration_minutes: 6,
        blog_url: '',
        departamento_id: null,
    };
}
